export const GET_STYLE_API_PATH = "/api/GetStyles.php";
export const GET_PARTITION_API_PATH = "/api/GetPartition.php";
export const GET_SIZE_API_PATH = "/api/GetSize.php";
export const GET_PROJECT_API_PATH = "/api/GetProjects.php";
export const GET_TYPE_API_PATH = "/api/GetTypes.php"
export const SEARCH_PROJECT_API_PATH = "/api/SearchProject.php";
export const SEARCH_PROJECT_PAGE_NUMBER_API_PATH = "/api/SearchProjectPageNumber.php";
export const SEARCH_PROJECT_DETAIL_API_PATH = "/api/SearchProjectDetail.php";
export const SUBMIT_ENQUIRY_API_PATH = "/api/SubmitEnquiry.php";
export const SUBMIT_ENQUIRY_CALCULATOR_API_PATH = "/api/SubmitEnquiryCalculator.php";
export const GET_MEDIA_API_PATH = "/api/GetMedia.php";
export const GET_NEWS_API_PATH = "/api/GetNews.php";
export const GET_MEDIA_OR_NEWS_DETAIL_API_PATH = "/api/GetMediaOrNewsDetail.php";
export const GET_SLIDE_SHOW_IMAGES_API_PATH = "/api/GetSlideShowImages.php";

export const MOBILE_LAYOUT_WIDTH = 768;