import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import 'bootstrap/dist/css/bootstrap.css';
import './css/main.css';
import Vue2TouchEvents from 'vue2-touch-events';
import { MOBILE_LAYOUT_WIDTH } from './constants';

Vue.config.productionTip = true;
Vue.use(Vue2TouchEvents);
Vue.prototype.$globalData = Vue.observable({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isMb: window.innerWidth < MOBILE_LAYOUT_WIDTH,
    windowSizeTimeoutObj: null,
    windowScrollY: null,
    windowScrollTimeoutObj: null,
    displayGoToTopButton: null
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
