<template>
  <div id="app">
    <nav
      id="main-menu"
      v-bind:class="{ 'main-menu-enabled-rwd': isEnabledRwdBtn }"
      class="main-menu"
    >
      <div class="wrapper">
        <div class="logo-wrapper">
          <router-link to="/"
            ><img class="logo" src="./assets/company_logo.jpg"
          /></router-link>
        </div>
        <div
          v-bind:class="{ 'item-wrapper-enabled': isEnabledRwdBtn }"
          class="item-wrapper"
        >
          <div class="item">
            <router-link to="/About" v-on:click.native="rwdMenuBtnClick"
              >關於我們</router-link
            >
          </div>
          <div class="item">
            <router-link to="/Project" v-on:click.native="rwdMenuBtnClick"
              >設計項目</router-link
            >
          </div>
          <div class="item">
            <router-link to="/News" v-on:click.native="rwdMenuBtnClick"
              >最新動向</router-link
            >
          </div>
          <div class="item">
            <router-link to="/Media" v-on:click.native="rwdMenuBtnClick"
              >媒體介紹</router-link
            >
          </div>
          <div class="item">
            <router-link
              to="/Home/contactUs"
              v-on:click.native="rwdMenuBtnClick"
              >聯絡我們</router-link
            >
          </div>
          <div class="item">
            <router-link to="/Home/joinUs" v-on:click.native="rwdMenuBtnClick"
              >加入我們</router-link
            >
          </div>
          <div class="item">
            <router-link to="/Calculator" v-on:click.native="rwdMenuBtnClick"
              >報價計算機</router-link
            >
          </div>
          <div class="icon-item-wrapper">
            <div class="icon-item">
              <a
                href="https://www.facebook.com/pages/InHomes-Interior-Design-%E5%9E%8B%E5%AE%B6%E5%AE%A4%E5%85%A7%E8%A8%AD%E8%A8%88/252475831602560?ref=hl"
                target="_blank"
              >
                <img src="./assets/icon_fb_black.png" />
              </a>
            </div>
            <div class="icon-item">
              <a
                href="https://www.instagram.com/inhomes_interior_design"
                target="_blank"
              >
                <img src="./assets/icon_ig_black.png" />
              </a>
            </div>

            <div class="icon-item">
              <a
                href="https://www.youtube.com/channel/UCz8YSSYMHwbnf42cXXlB-8g"
                target="_blank"
              >
                <img
                  src="./assets/icon_youtube_black.png"
                  style="height: auto"
                />
              </a>
            </div>

            <div class="icon-item-white">
              <a
                href="https://www.facebook.com/pages/InHomes-Interior-Design-%E5%9E%8B%E5%AE%B6%E5%AE%A4%E5%85%A7%E8%A8%AD%E8%A8%88/252475831602560?ref=hl"
                target="_blank"
              >
                <img src="./assets/icon_fb_white.png" />
              </a>
            </div>
            <div class="icon-item-white">
              <a
                href="https://www.instagram.com/inhomes_interior_design"
                target="_blank"
              >
                <img src="./assets/icon_ig_white.png" />
              </a>
            </div>

            <div class="icon-item-white">
              <a
                href="https://www.youtube.com/channel/UCz8YSSYMHwbnf42cXXlB-8g"
                target="_blank"
              >
                <img
                  src="./assets/icon_youtube_white.png"
                  style="height: auto"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="rwd-menu-btn" v-on:click="rwdMenuBtnClick">
          <img src="./assets/rwd_btn.png" style="width: 100%; height: 100%" />
        </div>
      </div>
    </nav>
    <div
      v-on:click="goToTopClick"
      v-bind:class="{ active: $globalData.displayGoToTopButton }"
      class="btn-go-to-top"
    >
      <img src="./assets/go_to_top.jpg" />
    </div>

    <transition name="fade" mode="out-in">
      <router-view class="content-view" :key="$route.path" />
    </transition>
  </div>
</template>

<script>
import { MOBILE_LAYOUT_WIDTH } from "./constants";

export default {
  name: "app",
  data() {
    return {
      isEnabledRwdBtn: false,
    };
  },
  created: function () {
    let that = this;
    window.addEventListener("resize", () => {
      clearTimeout(that.$globalData.windowSizeTimeoutObj);
      that.$globalData.windowSizeTimeoutObj = setTimeout(() => {
        that.$globalData.windowWidth = window.innerWidth;
        that.$globalData.windowHeight = window.innerHeight;
        that.$globalData.isMb = window.innerWidth < 768;
      }, 180);
    });

    that.$globalData.windowWidth = window.innerWidth;
    that.$globalData.windowHeight = window.innerHeight;
    that.$globalData.isMb = window.innerWidth < 768;

    window.addEventListener("scroll", () => {
      clearTimeout(that.$globalData.windowScrollTimeoutObj);
      that.$globalData.windowScrollTimeoutObj = setTimeout(() => {
        that.$globalData.windowScrollY = window.scrollY;
        if (window.scrollY >= 100) that.$globalData.displayGoToTopButton = true;
        else that.$globalData.displayGoToTopButton = false;
      }, 100);
    });

    that.$globalData.windowScrollY = window.scrollY;
    if (window.scrollY >= 100) that.$globalData.displayGoToTopButton = true;
    else that.$globalData.displayGoToTopButton = false;
  },
  methods: {
    rwdMenuBtnClick: function () {
      if (window.innerWidth < MOBILE_LAYOUT_WIDTH)
        this.isEnabledRwdBtn = !this.isEnabledRwdBtn;
    },
    goToTopClick: function () {
      if (this.$globalData.displayGoToTopButton) window.scrollTo(0, 0);
    },
  },
};
</script>