import Vue from 'vue';
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    scrollBehavior: function (to) {
        if (to.hash) {

            let offsetY = window.innerWidth < 768 ? 60 : 80;

            return {
                selector: to.hash,
                offset: { x: 0, y: offsetY }
            };
        }
    },
    routes: [
        {
            props: true,
            path: "/Home/:section?",
            alias: "/",
            name: "Home",
            component: () => import("./components/Home")
        },
        {
            path: "/About",
            name: "About",
            component: () => import("./components/About")
        },
        {
            path: "/Project",
            name: "Project",
            component: () => import("./components/Project")
        },
        {
            path: "/News",
            name: "News",
            component: () => import("./components/News")
        },
        {
            path: "/Media/:type?",
            name: "Media",
            component: () => import("./components/Media")
        },
        {
            path: "/Calculator",
            name: "Calculator",
            component: () => import("./components/Calculator")
        },
        {
            path: "*",
            component: () => import("./components/Home")
        }
    ]
});